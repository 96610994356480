<template>

    <div class="loader" v-if="loading" v-loading="loading"></div>

    <template v-if="thisCategory && sortedProducts">

        <teleport to="head">
            <title>{{ thisCategory[prefs.lang].meta_title }}</title>
        </teleport>

        <teleport to="#top_benefit">
            <div v-if="thisCategory[prefs.lang].description" class="benefit_area ba_cat">
                <div class="cont" v-html="thisCategory[prefs.lang].description"></div>
            </div>
        </teleport>

    <!--    <ul v-if="mainCategories" class="cats">-->
    <!--        <li v-for="c in mainCategories">-->
    <!--            <router-link :to="'/products/'+c.url" :class="{ bold: url === c.url }">{{ c[prefs.lang].name }}</router-link>-->
    <!--        </li>-->
    <!--    </ul>-->

        <el-breadcrumb v-if="thisCategory.id != 67" id="catTop" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><router-link :to="'/products/all'">{{ categories.find(i => i.id == 67)[prefs.lang].name }}</router-link></el-breadcrumb-item>
            <el-breadcrumb-item v-if="thisCategory.parent_id != 67">
                <router-link :to="'/products/'+categories.find(i => i.id == thisCategory.parent_id).url">{{ categories.find(i => i.id == thisCategory.parent_id)[prefs.lang].name }}</router-link>
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ thisCategory[prefs.lang].name }}</el-breadcrumb-item>
        </el-breadcrumb>
        <span v-else id="catTop"></span>


        <!--    <p v-if="category">{{ category }}</p>-->
        <h1>{{ thisCategory[prefs.lang].name }}</h1>

        <!--    <h1 v-if="category">{{ category.i.name }}</h1>-->

        <ul v-if="categories && thisCategory.children" class="cats">
            <li v-for="c in thisCategory.children">
                <router-link :to="'/products/'+c.url" :class="{ bold: url === c.url }">{{ c[prefs.lang].name }}</router-link>
            </li>
        </ul>
    <!--    <ul v-if="category">-->
    <!--        <li v-for="c in category.children">-->
    <!--            <router-link :to="'/products/'+c.id" :class="{ bold: id === c.id }">{{ c.i.name }}</router-link>-->
    <!--        </li>-->
    <!--    </ul>-->

        <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-input id="prodFilter" prefix-icon="el-icon-search" v-model="prodFilter" type="text" :placeholder=txt[prefs.lang].find_in_cat onfocus="setTimeout(() => {this.select()}, 1)" @keydown.esc="prodFilter = ''" clearable></el-input>
            </el-col>

            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                <el-select>
                    <el-option v-for="sortOpt in sortOptions" @click="prefs.sort=sortOpt" :label=txt[prefs.lang].sort[prefs.sort]>{{ txt[prefs.lang].sort[sortOpt] }}</el-option>
                </el-select>
            </el-col>

            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                <el-button-group>
                    <el-button @click="prefs.show_as='card'" icon="el-icon-menu" style="margin:0 !important;"></el-button>
                    <el-button @click="prefs.show_as='list'" icon="el-icon-s-order" style="margin:0 !important;"></el-button>
                </el-button-group>
            </el-col>
        </el-row>

        <!--    && prefs.show_as === 'card'-->
        <el-row type="flex" class="Catalog" v-if="sortedProducts && prefs.show_as === 'card'">
            <CatalogItem
                v-for="product of sortedProducts"
                :key="product.id"
                :product="product"
                :category_id="thisCategory.id"
                :prodFilter="prodFilter"
            />
        </el-row>

        <table class="catalog_table" v-else-if="sortedProducts && prefs.show_as === 'list'">
            <CatalogItem
                v-for="product of sortedProducts"
                :key="product.id"
                :product="product"
                :category_id="thisCategory.id"
                :prodFilter="prodFilter"
            />
        </table>

    </template>

    <template v-else>
        <teleport to="head">
            <title>{{ txt[prefs.lang].not_found }}</title>
        </teleport>

        <h1 class="big">{{ txt[prefs.lang].not_found }}</h1>
        <div class="centered">
            <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].products }}</el-button></router-link>
        </div>

    </template>

</template>


<script>
import CatalogItem from '@/components/CatalogItem'
import useCart, {log_action} from "@/use/cart"
import { ref, computed, watch, watchEffect, onMounted } from 'vue'
import { txt } from '@/use/txt'
import router from '@/router/index'

export default {
    setup(props) {

        const { prefs, me, rate, products, categories, sortOptions, log_action } = useCart()

        const prodFilter = ref('')
        let t = null

        watch(prodFilter, () => {
            clearTimeout(t)
            t = setTimeout(log_action, 1200, { type: 13, value: prodFilter.value.length ? prodFilter.value : 'EMPTY' })
        })

        onMounted(() => {

            var prodFilterEl = document.getElementById('prodFilter');

            document.addEventListener('keyup', e => {
                if (e.altKey && e.code.includes('Digit')) {
                    e.preventDefault()
                    let cat = categories.value.find(i => i.sort_order == e.code[5])
                    if (cat.id != thisCategory.value.id) router.push({ path: '/products/'+cat.url })
                    else if (!window.location.href.includes('#')) window.location.href += '#catTop'
                }
            })

            document.addEventListener('keydown', e => {
              if (e.which === 191) { // / (slash) – keydown only!
                e.preventDefault();
                prodFilterEl.focus();
              }
            })

            // for mobile
            if (prodFilterEl) {
              prodFilterEl.addEventListener('keyup', e => {
                    prodFilter.value = e.target.value
                })
            }

        })

        const sortedProducts = computed(() => {
            if (products.value) {
                let prods = products.value.slice()

                switch(prefs.sort) {
                    case "default": prods.sort((a,b) => a.sort - b.sort); break;
                    case "new": prods.sort((a,b) => b.id - a.id); break;
                    case "price_a": prods.sort((a,b) => a.price - b.price); break;
                    case "price_z": prods.sort((a,b) => b.price - a.price); break;
                    // case "old": prods.sort((a,b) => a.id - b.id); break;
                    case "name_a": prods.sort((a,b) => a[prefs.lang].name > b[prefs.lang].name ? 1 : -1); break;
                    case "name_z": prods.sort((a,b) => a[prefs.lang].name > b[prefs.lang].name ? -1 : 1); break;
                }

                return prods.filter(i => i.status)
            }
            else return null
        })

        // const nproducts = computed(() => fproducts.value ? fproducts.value.map(i => {
        //     return Object.assign({ price_rated: () => i.price*rate.value }, i)
        // }) : null)

        // const categories = useResult(
        //     useQuery(categoriesQuery, { status: true }).result, null, data => data.categories)
        // const allCategories = useResult(
        //     useQuery(allCategoriesQuery, { status: true }).result, null, data => data.categories)

        // const category = computed(() => allCategories.value ? allCategories.value.sort(
        //     (a, b) => a.lastname.localeCompare(b.lastname)
        // ) : null)

        // console.log('mc: '+JSON.stringify(mainCategories))
        const thisCategory = computed(() => categories.value ? categories.value.find(i => i.url == props.url) : null)

        // const prods = useResult(
        //     useQuery(productsQuery, vars).result)
        // const test = computed(() => products.value ? products.value.slice().sort(
        //     (a,b) => a.i.name.localeCompare(b.i.name)
        // ) : [])
        // const test = 'test'

        // const cat = ref(categories.find(i => i.id === props.id))
        // console.log(categories.value.find(i => i.id === props.id))

        // const category = useResult(
        //     useQuery(categoryQuery, catVars).result, null, data => data.category)

        // console.log(category)
        // const {result, updateQuery} = useQuery(categoryQuery, { id: props.id })
        // const category = useResult(result, null, data => data.category)


        // products.sort((a, b) => a.price - b.price)

        // function test() {
        //     axios.get('http://localhost:8000/test').then((response) => {
        //         console.log(response.data)
        //         test_res.value = response.data
        //     })
        // }

        return { products, sortedProducts, prodFilter, categories, prefs, txt, sortOptions, thisCategory }
    },
    components: {
        CatalogItem
    },
    props: {
        url: String
    }
}
</script>

<style>
    .bold {
        font-weight: bold;
    }
    .nodisp {
        display: none;
    }
    ul.cats {
        /*margin: 1em;*/
        padding: 1em;
        text-align: left;
    }
    .Catalog {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .catalog_table {
        width: 100%;
        table-layout:fixed;
    }
    .el-card {
        margin: 1em;
        text-align: center;
        border: none;
    }

    .price {
        margin: 0.3em;
        overflow-wrap: break-word;
    }
    .item_tr img {
        width: 25px;
        vertical-align: middle;
    }
    .item_tr td:nth-of-type(1) {width:60%; text-align: left;} /*Setting the width of column 1.*/
    .item_tr td:nth-of-type(2) {width:20%; text-align: center;}
    .item_tr td:nth-of-type(3) {width:20%; text-align: center;}

    .CatalogItem img {
        width: 200px;
    }



</style>